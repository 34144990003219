.products-container {
	margin-top: 18px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw; /* Full width of the viewport */
	height: 85vh; /* Full height of the viewport */
	padding: 0; /* Remove padding to make sure it fills the entire viewport */
	box-sizing: border-box; /* Ensure padding and borders are included in the element's total width/height */
}

.title {
	font-weight: bold;
	font-family: "Inter", sans-serif;
	margin-bottom: 30px;
}
.products-context {
	margin: 5px auto;
	margin-bottom: 30px;
	font-family: "Inter", sans-serif;
	min-width: 40vw;
}
.buttons {
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.product-btn {
	width: 40vw;
	margin-bottom: 22px;
	font-family: "Inter", sans-serif;
	background-color: linear-gradient(to right, #1645ff 0%, #006de9 77%);
}

@media only screen and (max-width: 767px) {
	.product-btn {
		width: 80vw;
	}
}

/* ////////////////////////////////////////////////// */

.scroll-text {
	position: relative;
	overflow: hidden;
	height: 50px; /* Adjust height as needed */
}

.scroll-content {
	font-family: "Inter", sans-serif;
	display: flex; /* Use flexbox for alignment */
	white-space: nowrap; /* Prevent wrapping */
	animation: scroll 20s linear infinite; /* Set the duration for scrolling */
}

.scroll-content span {
	font-size: 18px; /* Adjust font size as needed */
	color: white;
	background-color: #0c5e99;
	padding: 10px 50px; /* Adjust padding as needed */
}

/* Keyframes for seamless scrolling */
@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(
			-50%
		); /* Move left by half the width of the entire content */
	}
}
