/* Gallery.css */
.desktop {
	display: block;
}

.gallery-context {
	margin: 25px auto 40px;
	font-family: "Inter", sans-serif;
	width: 64vw;
}

.mobile {
	display: none;
}

.desktop {
	margin: 20px auto;
}

.gallery-row {
	width: 100%;
	justify-content: center;
}

.collage-item {
	height: 20vh;
	width: auto;
	margin-bottom: 20px;
	transition: transform 0.3s ease;
}

.desktop > .row:nth-child(4) {
	gap: 2vh;
}

.desktop > .row:nth-child(5) {
	gap: 0.5vh;
}

/* Fullscreen image styles */
.fullscreen-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2000;
}

.fullscreen-image {
	max-width: 90%;
	max-height: 90%;
}

/* Media query for mobile screens */
@media only screen and (max-width: 767px) {
	.desktop {
		display: none;
	}

	.mobile {
		display: block;
	}

	.collage-item {
		height: auto;
		width: 70%;
		margin: 10px;
	}
}

.collage-item:hover {
	transform: scale(1.1);
}
