.home-gradient {
	background: linear-gradient(to right, #1645ff 0%, #006de9 77%);
	height: 100%;
	align-items: stretch;
	display: flex;
}

.home-mission {
	margin: auto;
}

.home-title {
	color: white;
	font-family: "Malgun Gothic", sans-serif;
	font-weight: bold;
	font-size: clamp(28px, 4vw, 80px);
	padding: 50px 0 20px;
}

.line {
	background-color: white;
	height: 2px;
	width: 90%;
	margin: auto;
	z-index: 999999;
}

.content {
	color: white;
	font-family: "Malgun Gothic", sans-serif;
	font-size: clamp(10px, 1.6vw, 26px);
	padding: 40px;
}

/* //////////////////////////// */

.hero-image {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	overflow: hidden;
}

.hero-image img {
	height: 100%;
	width: 100%;
}

.subhero-image {
	display: flex;
	justify-content: flex-end;
	align-items: top;
	overflow: hidden;
}

.subhero-image img {
	width: 100%;
}

/* //////////////////// */

.emergency-container {
	display: flex;
	align-items: center;
}

.ophours {
	margin-left: 2vw;
	width: 10vw;
}

.emergency-words {
	margin-left: 2vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

/* //////////////////////// */

.section2 {
	padding: 20px 30px;
	margin: auto;
}

.emergency-content {
	padding-bottom: 20px;
}

.emergency-title {
	font-family: "Malgun Gothic", sans-serif;
	font-weight: bold;
	font-size: clamp(40px, 2.3vw, 50px);
}

.emergency-subtitle {
	font-family: "Malgun Gothic", sans-serif;
	font-weight: bold;
	letter-spacing: 7px;

	font-size: clamp(30px, 2vw, 60px);
}

.service-title {
	font-family: "Malgun Gothic", sans-serif;
	font-weight: bold;
	font-size: 40px;
}

.box {
	width: 45px;
	border: 2px black solid;
	padding: 8px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.footer {
	align-items: center;
	padding: 20px;
}

.contact-info {
	display: flex;
	flex-direction: row;
	font-family: "Inter", sans-serif;
	margin: 5px;
}

.contact-item {
	display: flex;
	align-items: center;
	gap: 5px;
	padding-bottom: 20px;
	margin: 0px;
}

.contact-item i {
	font-size: 20px;
	text-align: center;
}

.contact-item h6 {
	font-size: clamp(14px, 1.6vw, 18px);
}

.contact-text {
	display: flex;
	flex-direction: column;
	line-height: 1.2;
}

.contact-text h6 {
	margin-bottom: 3px;
	font-size: clamp(14px, 1.6vw, 18px);
}

.contact-text p {
	margin: 0;
	font-size: clamp(14px, 1.6vw, 16px);
}

.logo-section {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
	margin: auto;
	text-align: center;
}

.logo-section img {
	max-height: 80px;
	margin: 5px;
}

.mobilecollage {
	display: none;
}

/* ////////////// */

/* Media query for phones */
@media only screen and (max-width: 767px) {
	.line {
		width: 60%;
		margin: auto;
	}

	.contact-item i {
		font-size: 16px;
	}
	.emergency-container {
		margin: 10px;
	}

	.box {
		width: 34px;
		padding: 6px;
	}

	.calls {
		border: 2px black solid;
		border-radius: 16px;
	}

	.contact-info {
		margin: 5px;
		flex-direction: column;
	}

	.contact-item {
		gap: 14px;
	}

	.ophours {
		width: 110px;
		margin: 0px;
	}

	.logo-section {
		margin-top: 15px;
	}

	.logo-section img {
		max-height: 40px;
	}

	.featured.hero-desktop {
		display: none;
	}

	.hero-image img {
		height: 100%;
	}

	.mobilecollage {
		display: block;
	}
	.subhero-image {
		display: none;
	}
	.emergency-words {
		margin-left: 18px;
	}
	.content {
		padding: 0px 20px;
		padding-top: 20px;
	}
	.home-title {
		padding: 20px 10px 0px !important;
	}
}
